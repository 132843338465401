.event-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%; /* Adjust height as needed */
    text-align: center;
    margin-top: 20px; /* Add top margin */
    margin-bottom: 20px; /* Add bottom margin */
  }
  
  .event-details h2 {
    margin-bottom: 20px; /* Add space below organization name */
  }
  
  .event-details img {
    width: 30%; /* Adjust width as needed */
    height: auto;
    margin-bottom: 20px; /* Adjust as needed */
    padding: auto;
  }
  
  .event-details p {
    margin: 5px;
    font-size: 20px; /* Adjust this value as needed */
    margin-bottom: 10px;
    margin-left: 5px;
  }
  
  /* Media query for screens smaller than 400px */
  @media (max-width: 400px) {
    .event-details img {
      width: 100%; /* Increase the image width to 100% for smaller screens */
    }
  }
  