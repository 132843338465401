.contact-info-container {
  text-align: center;
  padding: 20px;
  line-height: 1.5; /* Add a little space between lines */
  z-index: 1; 
  padding: 20px;
}
.contact-info-container p {
  margin-bottom: 30px; /* Increased bottom margin for more space */
}

.contact-email {
  font-size: 1.5em;
  margin-top: 20px;
}

h2 {
  margin-bottom: 20px;
}

.event-removal-text {
  font-size: 0.9em; /* Smaller font size */
  margin-top: 50px; /* Extra space above the statement */
  color: #666; /* Optional: different color for this text */
}





