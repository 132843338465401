.privacy-policy-container {
   
    margin-left: auto;  /* Center the container horizontally */
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centers content vertically */
    padding: 20px;
    width: 70%; /* Reducing width to make content more centered */
    height: auto; /* Let content determine the height */
    box-sizing: border-box;
    max-width: 800px;
    
}

/* Increase space between sections */
.privacy-policy-container section {
    margin-bottom: 50px;
}
