/* NavbarSyles.css */ 
.header{
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color:#F1CE94;    ;
    color: rgb(93, 91, 91);
    z-index: 9999;
    
}

h1,a{

    color: black; 

}


.nav-menu{
    display: flex;

}

.nav-menu li{
    padding: 0 1rem;

}

.nav-menu a {
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
}

.hamburger {
    display: none;
}

.ucla-logo {
    height: 150%; 
    margin-right: 5px;

}

@media screen and (max-width:1240px){
    .hamburger{
        display: block;
        z-index: 9999;
        background-color: #F1CE94; 

    }
    .nav-menu{
        position:absolute;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align : center;
        width:100%;
        transition:0.3s;
        z-index: 2;
        background:rgba(114, 110, 110, 0.9)
    
    }

    .nav-menu.active{
        left:0;
    }

    .nav-menu li{

        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 2rem;

    } 
        
}

