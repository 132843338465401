.events-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    
}
h2 a {
    color: black;
}

.events-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;  
    
    

}

.bigBoldBlack {
    font-size: 36px;
    font-weight: bold;
    color: black;
    margin-top: 10px;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    .textStyle {
      text-align: center;
    }
  }

.event-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: visible;
    padding: 1rem;
    text-align: center;
    z-index: 1;
    width: 90%;
    margin: 5px auto;
    max-height: 600px;
    background-image: url("https://www.transparenttextures.com/patterns/corrugation.png");


    
}

.event-item h2 {
    margin-bottom: 10px;
    word-break: break-word;
    overflow-wrap: break-word;
    font-size: 1.5em;
}

.event-item img {
    max-height: 70%;
    width: auto;
}

.event-item p {
    margin-bottom: 10px;
}

.event-item a {
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.date-picker-container {
    position: absolute;
    width: 60%;
    display: flex;
   padding-bottom: 15rem;
    z-index: 20;
   
    
}


.highlighted-date {
    background-color: #0961c0;
    color: white;  /* Set the text color to white to ensure the date is readable */
}

.date-picker-container > button {
    padding: 10px 25px;
    background-color: #F1CE94; /* Primary blue color */
    margin-left: 15px;
    border: none;
    border-radius: 4px; /* A subtle rounded corner */
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1em;
    font-weight: 600; /* Slightly bold text */
    text-transform: uppercase; /* Uppercase lettering for a touch of formality */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* A subtle shadow to lift the button slightly off the surface */
    outline: none;
   
    
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.date-picker-container > button:hover {
    background-color: #7FA9B0; /* Slightly darker blue on hover */
}
/* If you want to make the text inside the button bigger: */
.date-picker-container button {
    font-size: 1.2em; /* Adjust as needed */
    color: black; 
}


:root {
    --btn-font-size: 1.2em;
    --btn-padding: 10px 20px;
}

.date-picker-container > button {
    padding: var(--btn-padding);
    font-size: var(--btn-font-size);
}

.react-calendar__tile--active {
    background-color:#2774AE; /* This is the blue color you have */
    color: white; /* Text color */
}

/* For the hover effect, just like you've defined for other elements */
.react-calendar__tile--active:hover {
    background-color: #2774AE; /* Slightly darker blue for hover */
}

.top-arrow {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;  /* or any color of your choice */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    z-index: 1000;  /* to ensure the button stays on top of other elements */
}

.top-arrow:hover {
    background-color: #555;  /* or any color of your choice for hover effect */
}



@media (max-width: 900px) {
    :root {
        --btn-font-size: 1em;
        --btn-padding: 8px 16px;
    }
}

@media (max-width: 768px) {
    :root {
        --btn-font-size: 0.9em;
        --btn-padding: 7px 14px;
    }
}

@media (max-width: 576px) {
    :root {
        --btn-font-size: 0.8em;
        --btn-padding: 6px 12px;
    }
}

@media screen and (max-width:767px) {
    .ucla-logo {
      height: 70%;  /* Adjust as per your design preference for landscape phones */
    }
  }
  
  /* For portrait phones */
  @media screen and (max-width:576px) {
    .ucla-logo {
      height: 60%;  /* Adjust as per your design preference for portrait phones */
    }
  }

  .react-calendar {
    position: absolute;
    z-index: 9;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background: antiquewhite;
    border: 2px solid black;
    width: 90%;  /* Adjust as needed */
    /* Other styles as needed */
}


.event-tags {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.event-tag {
    background-color: #007bff;
    color: white;
    padding: 3px 8px;
    border-radius: 15px;
    font-size: 0.8em;
}


@media (max-width: 900px) {
    /* Define CSS variables that scale with the viewport width */
    :root {
        --font-scale: calc(2em + (2 - 1) * ((100vw - 400px) / (900 - 400)));
        --image-width: calc(70% + 30 * (100vw - 400px) / (900 - 400));
        --margin-scale: calc(10px + 10 * ((100vw - 400px) / (900 - 400)));
        --padding-scale: calc(2px + 5 * ((100vw - 400px) / (900 - 400)));
    }

    .events-grid {
        grid-template-columns: 1fr;
    }

    .event-item {
        margin: var(--margin-scale);
        padding: var(--padding-scale);
    }

    .event-item h2 {
        font-size: var(--font-scale);
    }
  
    .event-item img {
        width: var(--image-width);
        overflow: visible;  /* Allow content to overflow */
    }

    .event-item p {
        padding-top: 2px;
        font-size: 0.8em;
    }

    
}

.feature-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px;
    flex-wrap: wrap; /* In case of smaller screens, it wraps to next line */
}

.feature-box {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #FAEBD7;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.icon {
    margin-bottom: 10px;
}

.icon img {
    width: 50px; /* You can adjust the size as needed */
    height: 50px; /* Keeping the image square */
}
.tags-list {
    position: absolute;
    z-index: 3;
    top: 45%;
    left: 50%; 
    transform: translateX(-50%);
    background: antiquewhite;
    width: 40%;  /* Adjust this to your liking */
    border: 1px solid #ddd;  /* Optional: added a border for clarity */
    padding: 10px;  /* Optional: added some padding for better appearance */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* This will create 5 columns */
    gap: 10px; /* Spacing between grid items */
    border-color: #000000;

}

.college-dropdown {
    background-color: antiquewhite; /* Antique white background */
    color: #333; /* Text color */
    border: 2px solid #8B7D7B; /* Fancy border */
    border-radius: 5px; /* Rounded corners */
    padding: 5px 10px; /* Padding inside the dropdown */
    font-family: 'Times New Roman', Times, serif; /* Font style */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style */
    outline: none; /* Remove default focus outline */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    margin-top: 10px; /* Space above the dropdown */
  }
  
  /* Style for options in the dropdown */
  .college-dropdown option {
    padding: 5px; /* Padding inside options */
    background: antiquewhite; /* Background for options */
  }
  
  /* Hover effect for dropdown */
  .college-dropdown:hover {
    border-color: #5C5C5C; /* Change border color on hover */
  }


    /* Adjust to 4 columns for medium screens */
 @media (max-width: 1200px) {
        .tags-list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    /* Adjust to 3 columns for small screens */
@media (max-width: 992px) {
        .tags-list {
            grid-template-columns: repeat(2, 1fr);
            top: 105%
        }
    }

    /* Adjust to 2 columns for extra small screens */
@media (max-width: 768px) {
        .tags-list {
            grid-template-columns: repeat(2, 1fr);
        }
        /* Adjust to 1 column for very small screens like mobile in portrait */
@media (max-width: 576px) {
        .tags-list {
            grid-template-columns: 1fr;
            width: 50%;
        }
    
    }
    }