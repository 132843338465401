@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500;600;700;800&display=swap');
*{
  box-sizing: border-box;
  margin:0;
  padding: 0;
  
}



body {
  
  font-family:'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: antiquewhite;
  

}

ul{
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.btn-light
 {

  background-color: transparent;

}

.btn:hover
{

  background-color: transparent;

}

